export default [
  // {
  //   title: "LIGA FORUM",
  //   description:
  //     "Студенческий образовательный форум «Liga Forum» — это открытая площадка для общения и обмена идеями.",
  //   img: require("@/assets/liga-forum.svg"),
  //   status: "Ожидание конкурса",
  //   link: "https://liga-forum.ru/",
  // },
  {
    title: "Студенческая весна РТ",
    description:
      "XI Республиканский фестиваль студенческого творчества «Студенческая весна Республики Татарстан» в 2022 году является региональным фестивалем студенческого творчества, проводимым в рамках реализации Программы поддержки и развития студенческого творчества «Российская студенческая весна»",
    img: require("@/assets/studvesna.svg"),
    stages: "123",
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "LigaProf",
    description:
      "Республиканская образовательная программа РМОО Лига студентов Республики Татарстан для руководителей органов студенческого самоуправления профессиональных образовательных организаций РТ",
    img: require("@/assets/liga-prof.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Первая лига",
    description:
      'Республиканская образовательная кадровая программа «Первая лига» – проект РМОО «Лига студентов Республики Татарстан», направленный на привлечение социально-активной и талантливой молодежи в общественную жизнь Республики Татарстан.Программа «Первая лига» проводится в два этапа: «Первая лига. Старт», включающая в себя 7 образовательных программ в городах Республики Татарстан, а также общую программу "Первая лига. Перезагрузка" в конце учебного года, где к участию приглашаются лучшие выпускники первого этапа.',
    img: require("@/assets/pervaya-liga.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Лига дебатов",
    description:
      "Республиканская школа «Лига Дебатов» – это образовательно-практическая программа, являющаяся опорной точкой для формирования и развития дебатерского движения в высших учебных заведениях и профессиональных образовательных организациях Республики Татарстан.\n",
    img: require("@/assets/liga-debatov.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Медиашкола «Слово за нами»",
    description:
      "Республиканская медиашкола «Слово за нами» — это образовательный цикл практических и теоретических занятий с экспертами из медиаиндустрии республики.",
    img: require("@/assets/mediashkola.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Дружба народов",
    description:
      "Спартакиада иностранных студентов Республики Татарстан «Дружба народов» является комплексным спортивным мероприятием и проводится с целью продвижения идей духовного единства, дружбы народов и межнационального согласия среди студенческой молодежи.",
    img: require("@/assets/drujba-narodov.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Лобачевский",
    description:
      "Конкурс научных работ студентов им. Н. И. Лобачевского — это повод научиться писать научные статьи, попробовать свои силы и знания, стать молодым ученым.",
    img: require("@/assets/lobachevskiy.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Конгресс студентов РТ",
    description:
      "Конгресс является главной площадкой, на которой определяются основные векторы развития студенчества, а также вырабатываются пути решения самых насущных вопросов студенческой молодежи, касающиеся социального самочувствия и социально-экономических вопросов студенческой молодежи.",
    img: require("@/assets/stud-kong.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
  {
    title: "Республиканский старостат",
    description:
      "I Республиканский старостат вузов Татарстана собрал 500 старост ООВО Республики Татарстан, в онлайн-формате участие приняли порядка 1000 старост ВУЗов Республики. Главная цель общего старостата— объединиться для совместного решения вопросов студенчества Республики. Старостат даст возможность узнать о возможностях для студенчества и об основных принципах взаимодействия с администрацией ВУЗов. Главным итогом стало создание единого сообщества старост академических групп для дальнейшего развития института старост Республики Татарстан.",
    img: require("@/assets/starosti.png"),
    status: "В разработке",
    link: "/",
    class: "events-grid__item--disabled",
  },
];
